<template>
  <div class="x01-layout white">
    <div class="x01-header">
      <v-app-bar dark color="primary">
        <v-toolbar-title>Swagger documentation</v-toolbar-title>
      </v-app-bar>
    </div>
    <div class="x01-content" id="swagger-ui" style="visibility: hidden"></div>
  </div>
</template>

<script>
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist'
import 'swagger-ui-dist/swagger-ui.css'
import { swaggerService } from '../services/invite/swaggerService'
import firebase from '../firebase'

export default {
  name: 'APIDoc',
  components: {},
  mounted () {
    this.buildSwagger()
  },
  methods: {
    customSwaggerRender () {
      document.querySelectorAll('h4').forEach((current) => {
        // Get sibling div
        const divElement = current.nextElementSibling
        if (divElement.children.length === 0) {
          current.style.display = 'none'
        }
      })
      document.querySelector('#swagger-ui').style.visibility = 'visible'
    },
    buildSwagger () {
      let presets = [
        SwaggerUIBundle.presets.apis,
        SwaggerUIStandalonePreset[2]
      ]
      const _this = this
      swaggerService.getOpenAPIDoc()
        .then(function (response) {
          const spec = response.data
          const ui = SwaggerUIBundle({
            spec: spec,
            dom_id: '#swagger-ui',
            deepLinking: false,
            filter: true,
            presets: presets,
            plugins: [
              SwaggerUIBundle.plugins.DownloadUrl
            ],
            layout: 'StandaloneLayout',
            onComplete: _this.customSwaggerRender
          })
          firebase.auth().currentUser.getIdToken().then((token) => {
            ui.preauthorizeApiKey('HTTPBearer', token)
            window.ui = ui
          })
        })
    }
  }
}
</script>

<style>
#swagger-ui .info {
  background: none !important;
  margin: 15px 0px 0px 0px !important;
}
</style>
